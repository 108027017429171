import React, { useEffect } from 'react';
import { Layout } from '../components/Layout';

const OrderPlaced = () => {
  // track conversions
  useEffect(() => {
    // protect against SSR
    if (typeof window === undefined || !window.gtag) {
      return;
    }

    window.gtag('event', 'conversion', { send_to: ['AW-978154405/19K1CNz73GUQpee10gM'] });
  }, []);

  return (
    <Layout
      title="Quote Request Placed | Axiom Geomatics | Calgary, Red Deer"
      description=""
      keywords=""
    >
      <main>
        <h1>Survey Quote Request Successful</h1>
        <p>
          Success! Your request for quote has been placed, Axiom Geomatics will be in touch with you
          shortly!
        </p>
      </main>
    </Layout>
  );
};

export default OrderPlaced;
